<template>
  <div class="combobox"
       :class="{ 'form-group--error': validator && validator.$error }"
       ref="comboboxmultiple"
       :style="{
                  '--min-width'     : minWidth,
                  '--max-height'    : maxHeight,
                  '--bg-selected'   : bgSelected,
                  '--bg-options'    : bgOptions,
                  '--color-selected': colorSelected,
                  '--color-options' : colorOptions,
                  '--border'        : border,
                  '--border-color'  : borderColor,
                }"
  >
    <label class="combobox__label" v-if="label"> {{ label }} </label>

    <div class="combobox__select">
      <div
          class="combobox__selected"
          @click="deployed = !deployed"
          :class="{'active': deployed}"
      >
        <span v-if="option">{{ option[valueText] }}</span>
        <span class="text-selected" v-else>{{ text }}</span>
        <span v-if="this.selects.length" class="count-selected">{{ this.selects.length }}</span>
        <i class="ez-icon-caret-down"/>
      </div>

      <div v-if="deployed" class="combobox__options active">
        <div class="combobox__filter" v-if="showSearch">
          <input type="search" :placeholder="$t('search')" v-model="search">
        </div>

        <ul class="combobox__list ez-slim-scroll" v-if="optionsFiltered && optionsFiltered.length > 0">
          <li @click="selectAll" v-if="search.length < 1">
            <span v-if="this.selects.length !== this.options.length">{{ $t('select_all') }}</span>
            <span v-else>{{ $t('unselect_all') }}</span>
          </li>
          <li @click="selectElement(option)"
              :class="{'selected': isSelected(option)}"
              :value='valueKey ? option[valueKey] : option' v-for="option in optionsFiltered"
              :key="valueKey ? option[valueKey] : option">{{ valueText ? option[valueText] : option }}
          </li>
        </ul>

        <div class="no-element" v-else>{{ $t('no_element') }}</div>
      </div>

      <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>

      <div v-if="validator && validator.$error">
        <span class="has-error animated headShake" v-if="!validator.required">{{ $t('field required') }}</span>
      </div>

      <span class="combobox__description" v-if="selects.length">{{ description }}</span>
    </div>

    <div class="sources-selected ez-slim-scroll"
         v-if="!hideSelected && selects.length"
    >
      <span v-for="source in selects"
            :key="valueKey ? source[valueKey] : source"
      >
          {{ displayLabel(source) }}
      </span>
    </div>

  </div>
</template>

<script>
import {uniq, uniqBy} from "lodash";

export default {
  name: "ComboBoxMultiple",
  props: {
    text: {type: String, default: 'N/A'},
    description: {type: String, default: ''},
    options: {type: Array, default: () => []},
    value: {type: Array, default: () => []},
    valueKey: {type: String, default: null},
    valueText: {type: String, default: null},
    change: {type: Function},
    validator: null,
    label: {type: String},
    error: {type: String},
    bgSelected: {type: String, default: '#f3f4fb'},
    bgOptions: {type: String, default: '#fff'},
    colorSelected: {type: String, default: '#8798AD'},
    colorOptions: {type: String, default: '#8798AD'},
    borderColor: {type: String, default: '#E7E8F2'},
    border: {type: String},
    minWidth: {type: String, default: '100%'},
    maxHeight: {type: String, default: '200px'},
    hideSelected: {type: Boolean, default: false},
  },
  data() {
    return {
      deployed: false,
      option: null,
      selects: [],
      search: '',
    }
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  watch: {
    deployed(value) {
      if (!value) this.search = '';
    }
  },
  computed: {
    showSearch() {
      return this.options.length > 5;
    },

    optionsFiltered() {
      const unique = this.valueKey ? uniqBy(this.options, this.valueKey) : uniq(this.options);

      if (this.search) {
        return unique.filter(option => {
          return this.search
              .toLowerCase()
              .split(' ')
              .every(search => {
                const optionText = this.valueText ? option[this.valueText] : option;
                return optionText.toLowerCase().includes(search);
              });
        });
      }

      return unique;
    }
  },
  mounted() {
    if (this.value?.length) {
      this.selects = [...this.value];
    }
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.comboboxmultiple;
      const target = e.target;

      if (el !== target && el && !el.contains(target)) {
        this.deployed = false;
      }
    },

    selectAll() {
      const isAllSelected = this.selects.length === this.options.length;
      if (isAllSelected) {
        this.selects = [];
      } else {
        this.selects = this.options.map(opt => opt[this.valueKey]);
        this.deployed = false;
      }

      this.$emit('input', this.selects);
      if (this.change) this.change(this.selects);
    },

    selectElement(option) {
      if (this.selects.findIndex(ele =>
          ele === option[this.valueKey]) === -1
      ) {
        this.selects.push(option[this.valueKey]);

      } else {
        this.selects.splice(this.selects.findIndex(
            ele => ele === option[this.valueKey] || ele[this.valueKey] === option[this.valueKey]), 1
        );
      }

      this.$emit('input', this.selects);
      if (this.change) this.change(this.selects);
    },

    displayLabel(value) {
      for (const s of this.options) {
        if (s[this.valueKey] === value) {
          return s[this.valueText]
        }
      }
    },

    isSelected(option) {
      const index = this.selects.findIndex(ele => ele === option[this.valueKey]);
      return index > -1;
    },
  }
}
</script>

<style scoped lang="scss">
.combobox {
  --bg: white;
  --border: 1px;
  --height: 2.5rem;
  position: relative;

  &__select {
    border-radius: 0.1875rem;
    min-width: var(--min-width);
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }

  &__selected {
    background: var(--bg-selected);
    border: var(--border) solid var(--border-color);
    border-radius: 0.1875rem;
    display: flex;
    align-items: center;
    height: 2.5rem;
    justify-content: space-between;

    span {
      color: var(--color-selected);
      font-weight: $font-regular;
      padding: 0 10px;
      font-size: .8rem;
    }

    .text-selected {
      flex: 1;
    }

    .count-selected {
      background: color(bg-primary);
      color: color(bg-white);
      border-radius: .1875rem;
      justify-self: flex-end;
    }

    i {
      color: color(bg-black3);
      border-radius: 3px;
      height: 25px;
      width: 25px;
      margin-right: 10px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      border-radius: 6px 6px 0 0;
    }
  }

  &__options {
    background: var(--bg);
    border: 1px solid color(border);
    border-radius: 0 0 6px 6px;
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    max-height: var(--max-height);
    top: 100%;
    overflow: auto;
    z-index: 999;

    &.active {
      border-top: 0;
      display: flex;
      flex-direction: column;
    }

    .no-element {
      width: 100%;
      color: color(bg-black3);
      font-size: .8rem;
      text-align: center;
      height: var(--height);
      line-height: var(--height);
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    height: var(--height);

    input {
      border: none;
      color: var(--color-selected);
      height: var(--height);
      padding: 0 10px;
      box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
      width: 100%;
      font-weight: $font-regular;
      font-size: .8rem;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-selected);
        font-size: .8rem;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--color-selected);
        font-size: .8rem;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--color-selected);
        font-size: .8rem;
      }
    }
  }

  &__list {
    color: var(--color-options);
    width: 100%;
    overflow: auto;
    flex: 1;

    li {
      display: flex;
      align-items: center;
      height: var(--height);
      padding: 0 10px;
      font-size: .8rem;
      width: 100%;

      &.selected {
        position: relative;

        &:after {
          content: '\e9cb';
          font-family: 'ezleadicon';
          position: absolute;
          right: 5px;
          color: color(bg-primary);
          font-size: 1rem;
        }
      }

      &:hover {
        background: color(bg-gray3);
        cursor: pointer;
      }
    }
  }

  &__description {
    color: var(--color-selected);
    font-weight: 400;
    padding: 0 3px;
    font-size: .8rem;
    margin-top: 3px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black3);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  select {
    background: var(--bg);
    border: var(--border) solid color(border);
    border-radius: 0.1875rem;
    color: #495057;
    height: 40px;
    font-size: .8rem;
    padding: .3rem .5rem;
    transition: .15s ease-in-out;
    width: 100%;

    &:focus {
      box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  .sources-selected {
    margin-top: .75rem;
    max-height: var(--max-height);
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    gap: .8rem;

    span {
      text-align: center;
      border: 1px solid color(border);
      background: color(bg-primary);
      color: color(bg-white);
      padding: 3px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: .1875rem;
      font-size: .8rem;
      text-transform: capitalize;
      font-weight: $font-bold;
    }
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "select_all": "Select All",
    "unselect_all": "Unselect All",
    "must_select": "Must select a value",
    "no_element": "No elements",
    "required_field": "Required field",
    "search": "Search..."
  },
  "es": {
    "select_all": "Marcar Todos",
    "unselect_all": "Desmarcar Todos",
    "must_select": "Debe seleccionar un valor",
    "no_element": "No hay elementos",
    "required_field": "Campo requerido",
    "search": "Buscar..."
  }
}
</i18n>